import EmblaCarousel, {
    EmblaOptionsType,
    EmblaCarouselType
} from 'embla-carousel'

import { addDotBtnsAndClickHandlers } from './EmblaCarouselDotButton'
import Autoplay from 'embla-carousel-autoplay'

import './base.scss';
import './embla.scss';

const OPTIONS: EmblaOptionsType = {}

document.addEventListener('DOMContentLoaded', () => {
  const emblaNode = <HTMLElement>document.querySelector('.carousel-slider .embla');

  if (!emblaNode) {
    return;
  }
  const viewportNode = <HTMLElement>emblaNode.querySelector('.embla__viewport');
  const dotsNode = <HTMLElement>emblaNode.querySelector('.embla__dots');

  const emblaApi = EmblaCarousel(viewportNode, OPTIONS, [Autoplay({ playOnInit: true, delay: 4000 })]);

  const onNavButtonClick = (emblaApi: EmblaCarouselType): void => {
    const autoplay = emblaApi?.plugins()?.autoplay;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  };

  const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
    emblaApi,
    dotsNode,
    onNavButtonClick
  );

  emblaApi.on('destroy', removeDotBtnsAndClickHandlers);
});
