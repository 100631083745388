import {MainNavigationScrollHandler} from "../../common/navigation/MainNavigationScrollHandler";

function apply3MagMenuButtonHandler() {

    function showOverlay(show: Boolean) {
        let overlayElement = document.getElementById('eee-overlay-menu');
        if (overlayElement) {
            overlayElement.style.display = show ? 'flex' : 'none';
        }
    }

    // close on click on background
    let backgroundElement = document.getElementById('eee-overlay-menu');
    backgroundElement?.addEventListener('click', (event) => {
        if (event.target === backgroundElement) {
            showOverlay(false);
            // TODo scroll to top
        }
    })

    let buttonElement = document.getElementById('eee-btn-show-overlay-menu');

    if (buttonElement) {
        buttonElement.addEventListener('click', () => {
            showOverlay(true)

        })

        let allButtonElements = document.querySelectorAll('#eee-overlay-menu ul li a');

        allButtonElements.forEach(element => {
            element.addEventListener('click', () => {
                showOverlay(false)
            })
        })
    }
}

function applyGlobalSearchButtonHandler() {
    // as we -unfortunately- render all main navigation sizes and also the submenu, we need to catch all search buttons...
    const searchButtonElements = document.querySelectorAll('.btn-show-global-search-field');
    const searchFieldCloseButtons = document.querySelectorAll('.icon-3mag-close');
    const searchFieldElements =  document.querySelectorAll('.searchbox');
    const searchFieldSearchIcon =  document.querySelectorAll('.search-icon');
    const searchFormElements = document.querySelectorAll('form');
    const searchFieldInputElements:NodeListOf<HTMLInputElement> = document.querySelectorAll('.search-input-element');

    if (searchButtonElements.length > 0) {
        searchButtonElements.forEach((searchButtonElement) => {
            searchButtonElement.addEventListener('click', () => {
                searchFieldElements.forEach(
                  (searchFieldElement) => {
                      searchFieldElement.classList.toggle('hidden');
                      searchFieldElement.classList.toggle('flex');
                  }
                )

                searchFieldInputElements.forEach((searchFieldInputElement) => {
                    searchFieldInputElement.focus();
                });

                    searchButtonElements.forEach((searchButtonElement) => {
                    searchButtonElement.classList.toggle('hidden');
                });
            })
        })
    }

    if (searchFieldCloseButtons.length > 0) {
        searchFieldCloseButtons.forEach((searchFieldCloseButton) => {
            searchFieldCloseButton.addEventListener('click', () => {
                searchFieldElements.forEach(searchFieldElement => {
                    searchFieldElement.classList.toggle('hidden');
                    searchFieldElement.classList.toggle('flex');
                });

                // show search button
                searchButtonElements.forEach((searchButtonElement) => {
                    searchButtonElement.classList.toggle('hidden');
                })
            })
        })
    }

    if (searchFieldSearchIcon.length > 0) {
        searchFieldSearchIcon.forEach((searchFieldSearchIcon) => {
            searchFieldSearchIcon.addEventListener('click', () => {
                searchFormElements.forEach((searchFormElement) => {
                    // only submit from search field input element with a value
                    searchFieldInputElements.forEach((searchFieldInputElement) => {
                        if (searchFieldInputElement.value) {
                            (searchFormElement.querySelector('input[name="q"]') as HTMLInputElement).value = searchFieldInputElement.value;
                        }
                    });

                    searchFormElement.submit();
                })
            })
        })
    }
}


function applyMenuOutsideClickHandler() {
    const categoryMenuElements = document.querySelectorAll('.category-menu');
    const redLineElements = document.querySelectorAll('.red-line');

    let clickedInsideMegaMenu = false;
    let clickedInsidCategoryMenu = false;

    // as we -unfortunately- render all main navigation sizes and also the submenu, we need to hide all elements with class
    // "menu-language-switcher" when clicking outside
    document.addEventListener('click', (event) => {
        clickedInsideMegaMenu = false;
        clickedInsidCategoryMenu = false;

        const megaMenuTriggerButtons = document.querySelectorAll('[data-target="#magazine-mega-menu"]');
        const categoryMegaMenuTriggerButtons = document.querySelectorAll('[data-target="#magazine-category-mega-menu"]');


        if (!(event.target as HTMLElement).classList.contains('icon-language-switcher') && !(event.target as HTMLElement).classList.contains('custom-display-toggle')) {
            const openLanguageMenuElements = document.querySelectorAll('.menu-language-switcher:not(.hidden)');
            if (openLanguageMenuElements.length > 0) {

                // set language switch button to inactive
                const languageSwitchButtons = document.querySelector('.btn-language-switcher.active');
                languageSwitchButtons.classList.toggle('active');

                openLanguageMenuElements.forEach((element) => {
                    element.classList.toggle('hidden');
                })
            }
        }


        // only close the mega menu, when all .mega-menu-container items do not contain the event target as Node
        document.querySelectorAll('.mega-menu-container').forEach((element) => {
            if (element.contains(event.target as Node)) {
                clickedInsideMegaMenu = true;
            }
        });

        // only close the category mega menu, when all .category-mega-menu-container items do not contain the event target as Node
        document.querySelectorAll('.category-mega-menu-container').forEach((element) => {
            if (element.contains(event.target as Node)) {
                clickedInsidCategoryMenu = true;
            }
        });

        if ((event.target as HTMLElement).classList.contains('close-menu')) {
            megaMenuTriggerButtons.forEach((element) => {
                element.classList.toggle('active');
                // if document body has class "overflow-hidden", remove it
                if (document.body.classList.contains('overflow-hidden')) {
                    document.body.classList.toggle('overflow-hidden');
                }
            });
        }

        if ((event.target as HTMLElement).classList.contains('close-category-menu')) {
            categoryMegaMenuTriggerButtons.forEach((element) => {
                element.classList.toggle('active');
                if (document.body.classList.contains('overflow-hidden')) {
                    document.body.classList.toggle('overflow-hidden');
                }
            });
        }


        if (!(event.target as HTMLElement).classList.contains('icon-3mag-menu')
          && !(event.target as HTMLElement).classList.contains('custom-display-toggle')
          && !clickedInsideMegaMenu) {

            // !as the new menu is a single responsive menu, this will work also on mobile, after we merge the new menu
            const openMegaMenuElements = document.querySelectorAll('.mega-menu-container:not(.hidden)');

            if (openMegaMenuElements.length > 0) {

                // set burger menu button to inactive
                megaMenuTriggerButtons.forEach((element) => {
                    element.classList.toggle('active');
                });

                openMegaMenuElements.forEach((element) => {
                    element.classList.toggle('hidden');
                })

                // allow body to scroll, if necessary
                if (document.body.classList.contains('overflow-hidden')) {
                    document.body.classList.toggle('overflow-hidden');
                }

                // hide all category menu elements, but remain space
                categoryMenuElements.forEach((element) => {
                    element.classList.toggle('invisible');
                })

                // hide all elements with class "red-line"
                redLineElements.forEach((element) => {
                    element.classList.toggle('invisible');
                })
            }
        }

        if (!(event.target as HTMLElement).classList.contains('icon-3mag-rectangle-grid')
          && !(event.target as HTMLElement).classList.contains('custom-display-toggle')
          && !(event.target as HTMLElement).classList.contains('open-category-menu')
          && !clickedInsidCategoryMenu) {

            // !as the new menu is a single responsive menu, this will work also on mobile, after we merge the new menu
            const openCategoryMegaMenuElements = document.querySelectorAll('.category-mega-menu-container:not(.hidden)');

            if (openCategoryMegaMenuElements.length > 0) {

                // set category menu button to inactive
                categoryMegaMenuTriggerButtons.forEach((element) => {
                    element.classList.toggle('active');
                });
                //
                openCategoryMegaMenuElements.forEach((element) => {
                    element.classList.toggle('hidden');
                })

                // allow body to scroll, if necessary
                if (document.body.classList.contains('overflow-hidden')) {
                    document.body.classList.toggle('overflow-hidden');
                }
            }
        }
    })
}



/**
 * e.g. <button class="custom-display-toggle" data-target="#my-modal">Toggle my modal</button>
 */
function applyCustomDisplayToggleHandlers() {
    const categoryMenuElements = document.querySelectorAll('.category-menu');
    const megaMenuElements = document.querySelectorAll('.mega-menu-container');
    const categoryMegaMenuElements = document.querySelectorAll('.category-mega-menu-container');
    const redLineElements = document.querySelectorAll('.red-line');
    const MegaMenuTriggerButtons = document.querySelectorAll('[data-target="#magazine-mega-menu"]');
    const categoryMegaMenuTriggerButtons = document.querySelectorAll('[data-target="#magazine-category-mega-menu"]');

    document.querySelectorAll('.custom-display-toggle').forEach((element => {
        element.addEventListener('click', (event) => {


            (event.currentTarget as HTMLElement).classList.toggle('active');
            let targetSelector = (event.currentTarget as HTMLElement).dataset?.target;
            if (targetSelector) {
                let targetElements = document.querySelectorAll(targetSelector);
                // avoid body to scroll
                if (!document.body.classList.contains('overflow-hidden')) {
                    document.body.classList.toggle('overflow-hidden');
                }

                // if target selector is #magazine-category-mega-menu, close mega menu
                if (targetSelector === '#magazine-category-mega-menu') {
                    megaMenuElements.forEach((element) => {
                        // if any mega menu element has not class "hidden", set it to hidden
                        if (!element.classList.contains('hidden')) {
                            element.classList.toggle('hidden');
                        }

                        // set burger menu button to inactive
                        MegaMenuTriggerButtons.forEach((element) => {
                            // if any mega menu element has class "active", set it to inactive
                           if (element.classList.contains('active')) {
                               element.classList.toggle('active');
                           }
                        });
                    });
                }

                if (targetSelector === '#magazine-mega-menu') {
                    categoryMegaMenuElements.forEach((element) => {
                        // if any mega menu element has not class "hidden", set it to hidden
                        if (!element.classList.contains('hidden')) {
                            element.classList.toggle('hidden');
                        }

                        // set category menu button to inactive, if necessary
                        categoryMegaMenuTriggerButtons.forEach((element) => {
                            if (element.classList.contains('active')) {
                                element.classList.toggle('active');
                            }
                        });
                    });
                }

                targetElements.forEach((element) => {
                    element.classList.toggle('hidden');
                })

                // hide all category menu elements, but remain space
                categoryMenuElements.forEach((element) => {
                    element.classList.toggle('invisible');
                })

                // hide all elements with class "red-line"
                redLineElements.forEach((element) => {
                    element.classList.toggle('invisible');
                })
            } else {
                console.debug(`No data-target defined on ${element.classList.toString()}`)
            }
        })

    }))
}

function applyMenuHandlers() {
    apply3MagMenuButtonHandler();
    applyGlobalSearchButtonHandler();
    applyMenuOutsideClickHandler();
    applyCustomDisplayToggleHandlers();
}

document.addEventListener('DOMContentLoaded', () => {
    applyMenuHandlers();

    const scrollHandler = new MainNavigationScrollHandler();
    scrollHandler.start();
})


