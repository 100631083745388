// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

//import "./navigation/various_navigation";
import "./navigation/main_navigation";
import "./embla/embla";

import "./embla.ts"

import mainMenu from "../common/menu";
window.mainMenu = mainMenu;

import emblaCategoryCarousel from "../common/emblaCategoryCarousel";

document.addEventListener('DOMContentLoaded', () => {
    emblaCategoryCarousel.init();
})
